import { gql, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useIsBaUserMustBeOnboardedActive, useIsFFBOV3Active } from "../../00_shared/hooks/useFeatureFlag.hook"
import { useAuthContext } from "../../Auth/auth.context"
import { MerchantOnboardingFlowStatus } from "../../business/enums/Merchant.enum"
import { IssuingCardsMenuEntry } from "../IssuingCards/routes/listMenuEntry"
import { ProAccountMenuEntry, TransfersMenuEntry } from "./routes/listMenuEntry"

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
  isMainAccount: boolean
  createdAt: string
}

type GetMerchantMainBusinessAccountResponse = {
  getMerchantMainBusinessAccount: { businessAccount: BusinessAccount[] }
}

const GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantMainBusinessAccount {
    getMerchantMainBusinessAccount {
      ... on GetMerchantMainBusinessAccountOutput {
        businessAccount {
          name
          balance
          ledgerId
          virtualIbanId
          isMainAccount
          createdAt
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

/**
 * @public
 */
export type BusinessAccountContextType = {
  isBaActive: boolean
  isBannerActive: boolean
  navigateToCreateTransfer: (options: { updateTransferOrigin: boolean }) => void
  returnToCreateTransferOrigin: () => void
  merchantMainAccountId?: string
}

const BusinessAccountContext = createContext<BusinessAccountContextType>({} as BusinessAccountContextType)

export const useBusinessAccountContext = () => useContext(BusinessAccountContext)

export const BusinessAccountProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const isBOV3FeatureFlagActive = useIsFFBOV3Active()
  const location = useLocation()
  const navigate = useNavigate()
  const [createTransferOrigin, setCreateTransferOrigin] = useState<null | string>(null)
  const { currentUser } = useAuthContext()

  const navigateToCreateTransfer = useCallback(
    (options: { updateTransferOrigin: boolean }) => {
      if (options.updateTransferOrigin) {
        setCreateTransferOrigin(location.pathname)
      }

      navigate("/transfers/create")
    },
    [location.pathname, navigate],
  )

  console.log(createTransferOrigin)

  const returnToCreateTransferOrigin = useCallback(() => {
    navigate(createTransferOrigin ?? "/transfers")
  }, [navigate, createTransferOrigin])

  const isActive = (path: string) => location.pathname.startsWith(path)

  const { error, data } = useQuery<GetMerchantMainBusinessAccountResponse>(GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY, {
    skip: !isBOV3FeatureFlagActive,
  })
  const [merchantMainAccountId, setMerchantMainAccountId] = useState<string | undefined>()
  const [isBaActive, setIsBaActive] = useState<boolean>(false)
  const [isBannerActive, setIsBannerActive] = useState<boolean>(false)
  const isBaUserMustOnboardedFFActive = useIsBaUserMustBeOnboardedActive()

  useEffect(() => {
    if (
      currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.PROCESSING &&
      isBaUserMustOnboardedFFActive
    ) {
      setIsBannerActive(true)
      return
    }

    if (
      data?.getMerchantMainBusinessAccount.businessAccount.length &&
      currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.VALIDATED
    ) {
      setIsBaActive(true)
      setMerchantMainAccountId(data.getMerchantMainBusinessAccount.businessAccount[0].ledgerId)
      return
    }
  }, [currentUser, data, isBaUserMustOnboardedFFActive])

  const isBaActiveWithOnBoardingFF = isBaUserMustOnboardedFFActive ? isBaActive : true
  const isOnActiveBannerRoute =
    isActive(ProAccountMenuEntry.to) || isActive(TransfersMenuEntry.to) || isActive(IssuingCardsMenuEntry.to)

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          reason: "An error occurred while fetching the main business account verification",
        },
      })
    }
  }, [error])

  return (
    <BusinessAccountContext.Provider
      value={{
        isBaActive: isBaActiveWithOnBoardingFF,
        isBannerActive: isOnActiveBannerRoute && isBannerActive,
        navigateToCreateTransfer,
        returnToCreateTransferOrigin,
        merchantMainAccountId,
      }}
    >
      {children}
    </BusinessAccountContext.Provider>
  )
}
